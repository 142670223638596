<template>
    <v-expansion-panel>
        <v-expansion-panel-header :class="$vuetify.breakpoint.smAndDown ? 'px-3 pt-1 pb-0' : ''">
            <v-row>
                <v-col cols=auto align-self="center">
                    <ProfilePhoto :src="oz.photo" heightImage="50" />
                </v-col>
                <v-col cols=auto md=auto align-self="center" :class="$vuetify.breakpoint.smAndDown ? 'pl-0' : ''">
                    <div :class="[$vuetify.breakpoint.smAndDown ? 'title' : 'headline', 'font-weight-medium', 'primary--text']">{{ oz.title }}</div>
                </v-col>
                <v-col cols=12 md=auto align-self="center" :class="$vuetify.breakpoint.smAndDown ? 'pb-5 pt-2' : ''">
                    <v-icon class="primary--text mr-2" small>near_me</v-icon>
                    <span >{{findRegionNameByCode(oz.region)}}</span>
                </v-col>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div class="grey--text caption">{{ $t("ozCard.description") }}</div>
            <div :style="$vuetify.breakpoint.smAndDown ? 'line-height: 1.7rem; white-space: pre-wrap;' : 'line-height: 1.8rem; white-space: pre-wrap;'" 
                 :class="[$vuetify.breakpoint.smAndDown ? 'body-2' : '', 'font-weight-regular', 'grey--text', 'text--darken-1', 'text-justify']">{{ oz.desc }}</div>
            <v-row class="mt-5 ml-3">
                <v-col cols=auto>
                    <router-link tag="span" :to="{name: 'WebAppProfile', params: {id: oz.ID}}"><v-btn>{{ $t("ozCard.profile") }}</v-btn></router-link>
                </v-col>
            </v-row>                
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import utilitiesMixin from '@/mixins/utilitiesMixin'
import ProfilePhoto from '@/components/partials/ProfilePhoto'
    export default {
        name: 'OZCard',
        mixins: [utilitiesMixin],
        components: {ProfilePhoto},
        props: {
            oz: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>