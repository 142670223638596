<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <v-row>
            <v-col cols=12 md=4>
                <v-btn text class="primary--text plain" @click="toggleFilters()" v-if="$vuetify.breakpoint.smAndDown">
                    <v-icon right class='mr-1'>mdi-filter</v-icon>
                    <span v-text="$t($store.state.showFilters ? 'organisations.filters.hide' : 'organisations.filters.show')"></span>
                </v-btn>
                <div v-if="$store.state.showFilters || !$vuetify.breakpoint.smAndDown">
                    <v-btn text class="primary--text plain my-5" @click="resetFilters()">
                        <v-icon right class='mr-1'>remove</v-icon>
                        <span>{{ $t("organisations.filters.clear") }}</span>
                    </v-btn>
                    <v-card class="py-5 px-5" flat style="border-radius: 20px">
                        <div class="subtitle-1 grey--text text--darken-1 mb-3">{{ $t("organisations.region.text") }}</div>
                        <v-row class="mx-2">
                            <v-col cols=12 class="pa-0">
                                <InputField
                                    class="mt-3"
                                    :label="$t('map.region.label')"
                                    icon="near_me"
                                    v-model="$store.state.filteredRegion"
                                    inputType="regionAutocomplete"
                                    :regionAll="true"
                                    :regionMy="$store.state.region ? true : false"
                                    :regionSlovakia="true"
                                    :inputValue="$store.state.filteredRegion"
                                />
                            </v-col>
                        </v-row>
                        <v-divider class="mb-5"></v-divider>
                        <div class="subtitle-1 grey--text text--darken-1">{{ $t("organisations.nameOrDescription.text") }}</div>
                        <v-row class="mx-2">
                            <v-col cols=12 class="pa-0 mr-2">
                                <InputField
                                    class="mt-3"
                                    :label="$t('organisations.nameOrDescription.label')"
                                    icon="search"
                                    v-model="$store.state.filteredText"
                                    inputType="text"
                                    :inputValue="$store.state.filteredText"
                                />
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-col>
            <v-col cols=12 md=8>
                <v-expansion-panels accordion>
                    <OZCard
                        v-for="(oz, index) in ozPage" :key="index"
                        :oz="oz"
                    />
                </v-expansion-panels>
                <v-pagination v-if="pageCount" class="mt-3" v-model="$store.state.ozPage" :length="pageCount" total-visible=9></v-pagination>
                <div v-else>{{ $t('organisations.notFound.text') }}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import axios from 'axios'
    import PopupDialog from '@/components/partials/PopupDialog'
    import OZCard from '@/components/organisations/OZCard'
    import InputField from '@/components/partials/InputField'

    export default {
        name: "WebAppOrganisations",
        components: {PopupDialog, OZCard, InputField},
        data() {
            return {
                ozList: [],
                dialog: false,
                ozPerPage: 10,
            }
        },
        computed: {
            pageCount: function() {
                return Math.ceil(this.displayedOz.length/this.ozPerPage)
            },
            ozPage: function() {
                return this.displayedOz.slice((this.$store.state.ozPage - 1)*this.ozPerPage, this.$store.state.ozPage*this.ozPerPage)
            },
            displayedOz: function() {
                return this.orderedOrganisations.filter(org => {
                    return (!this.$store.state.filteredText ||
                    org.title.toLowerCase().includes(this.$store.state.filteredText.toLowerCase()) ||
                    org.desc.toLowerCase().includes(this.$store.state.filteredText.toLowerCase())) &&
                    (!this.$store.state.filteredRegion || this.$store.state.filteredRegion == "data.allRegions" ||
                    this.filteredRegionCode == org.region)
                })
            },
            filteredRegionCode: function () {
                let region = this.$store.state.filteredRegion
                let code = 101
                if (region == 'data.myRegion') {
                    code = this.$store.state.region
                } else if (region.length && region != 'data.allRegions') {
                    const r = this.findRegionBy('displayValue', region);
                    code = r && r.region && r.region.code;
                }
                return code
            },
            orderedOrganisations: function() {
                let orderedOrganisations = [...this.ozList]
                const filteredTags = this.$store.state.filteredTags
                if (filteredTags.length > 1) {
                    orderedOrganisations.sort((a, b) => {
                        return this.sharedTags(b.tags, filteredTags) - this.sharedTags(a.tags, filteredTags)
                    })
                }
                return orderedOrganisations
            }
        },
        methods: {
            getData() {
                axios.get('/auth/get_oz')
                .then(
                    response => {
                        this.ozList = response.data.info;
                    }
                ).catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            },
            toggleFilters() {
                this.showFilters = !this.showFilters
            },
            containsTag (tags, filteredTags) {
                if (tags) {
                    for (let tag of tags) {
                        if (filteredTags.includes(tag)) {
                            return true
                        }
                    }
                }
                return false
            },
            resetFilters () {
                this.$store.state.filteredRegion = 'data.allRegions'
                this.$store.state.filteredText = ""
            },
            sharedTags(tags1, tags2) {
                let shared = 0
                if (tags1 && tags2 && tags1.length && tags2.length) {
                        [...tags1].forEach(tag => {
                    if (tags2.includes(tag)) {
                        shared += 1
                    }
                    })
                }
                return shared
            }
        },
        created() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>

</style>
